function fetchList(lista) {
    return lista.map(item => {
      if (Array.isArray(item.mensagens)) {
        return {
          ...item,
          mensagens: item.mensagens.join(",")
        };
      }
      return item;
    });
  }


export default fetchList;