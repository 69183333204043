import axios from "axios";

// Criando uma instância do axios
const api = axios.create({
  baseURL: "https://megasenderbackend.tech/megasender", 
  // baseURL: "http://127.0.0.1:8000/megasender", // URL base da API
  /*  timeout: 12000, */ // Tempo limite para a requisição (10 segundos)
});

// Função para criar uma nova instância (POST)
export const createInstancia = async (nomeInstance) => {
  try {
    const token = localStorage.getItem("userToken");
    if (!token) {
      return {
        success: false,
        message: "Token não encontrado. Faça o login novamente.",
      };
    }

    // Dados da nova instância
    const data = {
      nome_instance: nomeInstance, // Nome da instância que será criada
    };

    // Chamando a API para criar a instância
    const response = await api.post("/create_instance", data, {
      headers: {
        Authorization: `Bearer ${token}`, // Incluindo o token no cabeçalho de autorização
      },
    });

    /*  console.log(response.data); // Exibe os dados retornados da criação da instância */

    return { success: true, data: response.data }; // Retorna os dados da resposta
  } catch (error) {
    return handleApiError(error); // Trata erros de requisição
  }
};

// Função para obter todas as instâncias (GET)
export const getInstancias = async () => {
  try {
    const token = localStorage.getItem("userToken");
    if (!token) {
      return {
        success: false,
        message: "Token não encontrado. Faça o login novamente.",
      };
    }

    // Chamando a API para obter as instâncias, passando o e-mail no cabeçalho ou como parâmetro
    const response = await api.get("/instancias", {
      headers: {
        Authorization: `Bearer ${token}`, // Incluindo o token no cabeçalho de autorização
      },
    });
    /* console.log(response.data.instancias); */

    return { success: true, data: response.data }; // Retorna os dados da resposta
  } catch (error) {
    return handleApiError(error); // Trata erros de requisição
  }
};

export const deleteInstancia = async (instanceKey, tokenInstance) => {
  try {
    const token = localStorage.getItem("userToken");
    if (!token) {
      return {
        success: false,
        message: "Token não encontrado. Faça o login novamente.",
      };
    }

    /*   // Confirma que instanceKey e tokenInstance estão sendo enviados
    console.log("Deletando a instância com:", {
      instanceKey,
      tokenInstance,
    }); */

    // Requisição DELETE com instanceKey e tokenInstance como parâmetros na URL
    const response = await api.delete(
      `/delete_instance/${instanceKey}/${tokenInstance}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return { success: true, message: response.data.message };
  } catch (error) {
    return handleApiError(error);
  }
};

// Função de tratamento de erros
const handleApiError = (error) => {
  let errorMessage = "Erro desconhecido";
  if (error.response) {
    /*    console.error("Erro do servidor:", error.response.data); */
    errorMessage =
      error.response.data.detail ||
      error.response.data.message ||
      "Erro desconhecido no servidor.";
  } else if (error.request) {
    console.error("Sem resposta do servidor:", error.request);
    errorMessage =
      "Não foi possível se conectar ao servidor. Verifique sua conexão.";
  } else {
    console.error("Erro na configuração da requisição:", error.message);
    errorMessage = "Erro ao configurar a requisição: " + error.message;
  }

  return { success: false, message: errorMessage };
};

export default api;
