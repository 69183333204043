
import Sidebar from "../components/sidebar";
import "../css/listaTemplates.css";
import InformativoTemplates from "../components/templates/informativo";
import React, { useState } from 'react';
import ModalComponent from "../components/templates/modal";
import imagemCard01 from "../images/templates/undraw_summer_u79u.svg"
import imagemCard02 from "../images/templates/undraw_statistic-chart_6s7z.svg"
import CarroselSliderTemplates from "../components/templates/cardDashTemplates";
import fetchList from "../utils/converts";

const Templates = () => {

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const exemplo = [{"nome":"megasender","dias":2,"uiid":"9403d3ds32s9j9r","mensagens":["audio","texto","imagem","arquivo","video"]},{"nome":"megasender","dias":2,"uiid":"9403d3ds32s9j9r","mensagens":["audio","texto","imagem","arquivo","video"]},{"nome":"megasender","dias":2,"uiid":"9403d3ds32s9j9r","mensagens":["audio","texto","imagem","arquivo","video"]},{"nome":"megasender","dias":2,"uiid":"9403d3ds32s9j9r","mensagens":["audio","texto","imagem","arquivo","video"]}]

    // Isso serve para converter a lista refatorada exemplo em uma lista onde a sublista menssagens é retornada como split 

    const listaTemplates = fetchList(exemplo)

    const settings = {
        
      spaceBetween:10,
      slidesPerView:3,
      navigation:true,
    //   pagination:{ clickable: true },
      
    }

    return(
        <section className="containerTemplates">
            <Sidebar/>
            <main className="templates">
                <div className="containerTemplateSidebar">
                <InformativoTemplates imgGif={imagemCard01} title="Nº: Templates" data="0" enable={true}/>
                <InformativoTemplates imgGif={imagemCard02} title="Templates Criados" data="0" enable={true}/>
                <InformativoTemplates imgGif={null} title="Template?" data="Um template é uma configuração especial para criar um fluxo especifico, para aplicar em mais de uma instância." enable={false}/>
                
                </div>
                <div className="container-button-templates">
                    <button className="btn btn-outline-success" onClick={handleShow}>
                        CRIAR TEMPLATE
                    </button>

                    {/* Modal */}
                    <ModalComponent show={showModal} handleClose={handleClose} />

                </div>
                <div className="container-main-templates-conteudo">
                <CarroselSliderTemplates setting={settings} children={listaTemplates}/>
                </div>
            </main>

        </section>
    )
}




export default Templates;