// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Trash } from "@phosphor-icons/react";

const CarroselSliderTemplates = ({ setting, children }) => {
  console.log(children)
  const renderTooltip = (messages) => (
    <Tooltip id="button-tooltip">
      {messages}  {/* Concatena as mensagens com vírgula */}
    </Tooltip>
  );
  return (
    <Swiper  modules={[Navigation, Pagination, A11y]} {...setting}>
      {Array.isArray(children) ? (
        children.map((child) => (
          <SwiperSlide className="card"  key={child.uiid}>
            
              <button className="dismiss" type="button"><Trash size={32} weight="light" /></button> 
              <div className="header"> 
                <div className="image">
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </g></svg>
                </div> 
                <div className="content">
                  <span className="title-card-template">{child.nome}</span> 
                  <div className="message">
                    <div className="config-card-template">
                      <span>Uiid:</span>
                      <span className='uiid-code'>{child.uiid}</span>
                    </div>
                    <div className="config-card-template">
                      <span>Dias</span>
                      <span>{child.dias}</span>
                    </div>
                    <div className="config-card-template">
                      <span>Tipo</span>
                      <span>grupo</span>
                    </div>
                    <div className="config-card-template">
                      <span>Mensagens</span>
                      {/* OverlayTrigger para mostrar o tooltip */}
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip(child.mensagens)}
                    >
                      <span className="tooltip-target"><svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256"><path d="M142,176a6,6,0,0,1-6,6,14,14,0,0,1-14-14V128a2,2,0,0,0-2-2,6,6,0,0,1,0-12,14,14,0,0,1,14,14v40a2,2,0,0,0,2,2A6,6,0,0,1,142,176ZM124,94a10,10,0,1,0-10-10A10,10,0,0,0,124,94Zm106,34A102,102,0,1,1,128,26,102.12,102.12,0,0,1,230,128Zm-12,0a90,90,0,1,0-90,90A90.1,90.1,0,0,0,218,128Z"></path></svg></span>
                    </OverlayTrigger>
                    </div>
              
                    </div> 
                </div> 
                {/* <div className="actions">
                  <button className="history" type="button">History</button> 
                  <button className="track" type="button">Track my package</button> 
                </div>  */}
              </div> 
            
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide>
          <div>
            <h3>{children.nome}</h3>
            <p>Dias: {children.dias}</p>
            <p>ID: {children.uiid}</p>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
};



export default CarroselSliderTemplates;