import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo_imagem from "../images/logo-megasender-sidebar.svg";
import "../css/sidebar.css";

const Sidebar = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [inactivityTimeout, setInactivityTimeout] = useState(null); // Timer para inatividade
  const [sessionTimeout, setSessionTimeout] = useState(null); // Timer para a sessão fixa
  const [timeLeft, setTimeLeft] = useState(15 * 60); // Tempo restante da sessão em segundos
  const [inactivityTimeLeft, setInactivityTimeLeft] = useState(15 * 60); // Tempo restante para inatividade em segundos
  const [showCountdown, setShowCountdown] = useState(false); // Controla a exibição do contador

  const logoutUser = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("userName");

    setIsAuthenticated(false);
    navigate("/login");
    window.location.reload();
  };

  // Função para reiniciar o timer de inatividade
  const resetInactivityTimer = () => {
    if (inactivityTimeout) clearTimeout(inactivityTimeout);
    setInactivityTimeLeft(15 * 60); // Reinicia o contador de inatividade
    setShowCountdown(false); // Reseta o estado do contador visual

    const timeout = setTimeout(() => {
      logoutUser(); // Desloga o usuário após 15 minutos de inatividade
    }, 15 * 60 * 1000);
    setInactivityTimeout(timeout);
  };

  // Inicia o contador de tempo restante para a sessão fixa
  useEffect(() => {
    // Timer para sessão fixa de 15 minutos
    const sessionTimer = setTimeout(() => {
      logoutUser(); // Desloga o usuário após 15 minutos de sessão fixa
    }, 15 * 60 * 1000);
    setSessionTimeout(sessionTimer);

    // Contador decremental do tempo restante para a sessão fixa
    const sessionInterval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Contador decremental do tempo restante para inatividade
    const inactivityInterval = setInterval(() => {
      setInactivityTimeLeft((prevTime) => {
        const updatedTime = Math.max(prevTime - 1, 0);

        // Exibe o contador visual quando faltam 10 segundos
        if (updatedTime <= 10) {
          setShowCountdown(true);
        }

        return updatedTime;
      });
    }, 1000);

    // Limpeza dos timers ao desmontar
    return () => {
      clearTimeout(sessionTimer);
      clearInterval(sessionInterval);
      clearInterval(inactivityInterval);
    };
  }, []);

  useEffect(() => {
    // Configura o timer inicial para inatividade
    resetInactivityTimer();

    // Eventos que monitoram atividade do usuário
    const events = ["mousemove", "keydown", "click"];
    events.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );

    // Intervalo para decrementar o tempo restante de inatividade
    const inactivityInterval = setInterval(() => {
      setInactivityTimeLeft((prevTime) => {
        const updatedTime = Math.max(prevTime - 1, 0);

        // Exibe o contador visual quando faltam 15 segundos
        if (updatedTime <= 15) {
          setShowCountdown(true);
        }

        return updatedTime;
      });
    }, 1000);

    // Limpa os eventos e intervalos ao desmontar
    return () => {
      clearInterval(inactivityInterval);
      events.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimer)
      );
    };
  }, []);

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName") || "Usuário";
    let displayedName = storedUserName;

    if (storedUserName.includes("@")) {
      displayedName = storedUserName.split("@")[0].slice(0, 6);
    }

    setUserName(displayedName);
  }, []);

  /*   // Formata o tempo restante em minutos e segundos
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
 */
  return (
    <div className="container-sidebar">
      <div id="log-megasender-sidebar">
        <img src={logo_imagem} alt="Logo MegaSender" />
      </div>

      <div className="sidebar-menu">
        <div className="navgators">
          <Link draggable="false" className="link-formatted" to="/dashboard">
            <i className="fa-solid fa-house"></i> Instâncias
          </Link>

          <Link
            draggable="false"
            className="link-formatted"
            to="/templates"
          >
            <i className="fa-solid fa-newspaper"></i> Templates
          </Link>

          <Link draggable="false" className="link-formatted" to="/">
            <i className="fa-solid fa-chart-pie"></i> Métricas
          </Link>
          <Link draggable="false" className="link-formatted" to="/dashboard">
            <i className="fa-solid fa-star"></i> MegaClientes
          </Link>
        </div>

        {/* Exibe o tempo restante para logout por inatividade */}
        {/*   <div className="inactivity-timer">
            <i className="fa-solid fa-hourglass-half"></i>
            <span>Inatividade: {formatTime(inactivityTimeLeft)}</span>
          </div> */}

        <div
          className={`user-dropdown ${isSettingsOpen ? "open" : ""}`}
          onClick={() => setIsSettingsOpen(!isSettingsOpen)}
        >
          <div className="user-dropdown-header">
            <i className="fa-solid fa-cogs"></i>
            <span>Configurações</span>
            <i
              className={`fa-solid ${
                isSettingsOpen ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </div>

          <div className="user-dropdown-menu">
            <div className="user-dropdown-item">
              <i className="fa-solid fa-user-circle"></i>
              {userName}
            </div>
            <div className="user-dropdown-item" onClick={logoutUser}>
              <i className="fa-solid fa-sign-out-alt"></i> Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
