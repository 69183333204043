import React from 'react';
import styled from 'styled-components';

const InformativoTemplates = ({ imgGif, title, data, enable }) => {
    return (
        <CardContainer>
            {imgGif && <img className='gif-animation' src={imgGif} alt={title} />}
            <div>
                <h3 style={{ textAlign: enable ? 'end' : "left" }}>{title}</h3>
                <p style={{ fontSize: enable ? '25pt' : '14px',textAlign: enable ? 'end' : "left" }}>
                    {data}
                </p>
            </div>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    border: 1px solid #ddd; /* Borda clara */
    border-radius: 8px; /* Cantos levemente arredondados */
    padding: 16px; /* Espaçamento interno */
    margin: 12px; /* Espaçamento externo */
    max-width: 30%; /* Largura máxima de cada card, ajustando para 3 por linha */
    background-color: #fff; /* Fundo branco */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Sombra leve */
    text-align: left; /* Alinhamento do texto */
    font-family: Arial, sans-serif;
    display: flex;
    gap:5px;
    flex-direction: row;
    transition: transform 0.3s ease-in-out;


    h3 {

        font-size: 20px;
        color: #333;
        text-align: end;
        transition: color 0.3s ease;
    }


    p {
        margin: 0;
        font-size: 25pt;
        color: #666;
        transition: color 0.3s ease; 
        text-align: end;
    }



    &:hover {
        border-color: #aaa; /* Borda mais escura no hover */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Efeito de sombra mais forte */
        transition: all 0.3s ease-in-out;
        background-color: #111B21; /* Cor de fundo ao passar o mouse */
        color: #fff; /* Cor do texto do card */
        transform: scale(1.02);
    }

    &:hover p {
        color: #fff; /* Cor do parágrafo ao passar o mouse */
    }

    &:hover h3 {
        color:rgb(255, 255, 255); /* Cor do título ao passar o mouse */
    }
`;

export default InformativoTemplates;
