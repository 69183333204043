import "../css/instance-configure.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import RadioActionFluxo from "../components/radioSelectActionFluxo";
import Tablefluxo from "../components/table_fluxo";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import ContainerTempates from "../components/galeryCardsTemplates";
import Personalizar from "../components/painelAvancado";
import Sidebar from "../components/sidebar";
import { getInstancias } from "../services/instanciaApi";
import { verifyInstance, generateQRCode } from "../services/qrcodeApi";
import Loader from "../components/loader"; // Loader

import PhoneInput from "react-phone-input-2"; // Certifique-se de ter o pacote adequado

const InstanceView = () => {
  const isComponentCAllowed = process.env.REACT_APP_ALLOW_COMPONENT === "plus";
  const [selectedValue, setSelectedValue] = useState("personalizar");
  const [instanceData, setInstanceData] = useState(null); // Todas as instâncias
  const [selectedInstance, setSelectedInstance] = useState(null); // Instância selecionada
  const [loading, setLoading] = useState(true); // Controla o estado de carregamento
  const [error, setError] = useState(null); // Armazenar erro se ocorrer
  const [isConnected, setIsConnected] = useState(false); // Estado para verificar conexão

  const [result, setImageUrl] = useState(null);
  const [block, setblock] = useState(null);
  const [phone, setPhone] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { item } = location.state || {};

  const loadImageAndStatus = async (key, token, base_url) => {
    try {
      const verifyResponse = await verifyInstance({ key, token, base_url });
      if (verifyResponse.success) {
        const imageUrl = verifyResponse.data.imageUrl || null;
        setImageUrl(imageUrl);
        setIsConnected(verifyResponse.data.status === "on");
        setblock(imageUrl ? "block" : null);
      } else {
        setImageUrl(null);
        setIsConnected(false);
        setblock(null);
      }
    } catch (err) {
      setImageUrl(null);
      setIsConnected(false);
      setblock(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getInstancias();
        if (result.success && result.data && result.data.instancias) {
          setInstanceData(result.data.instancias);
          const selected = result.data.instancias.find(
            (inst) => inst.nome_instance === item?.nome_instance
          );
          setSelectedInstance(selected || null);

          if (selected) {
            await loadImageAndStatus(
              selected.key,
              selected.token_instance,
              selected.base_url
            );
          }
        } else {
          setError("Erro ao carregar as instâncias.");
        }
      } catch (err) {
        setError("Erro ao buscar instâncias.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [item]);

  useEffect(() => {
    if (selectedInstance) {
      loadImageAndStatus(
        selectedInstance.key,
        selectedInstance.token_instance,
        selectedInstance.base_url
      );
    }
  }, [selectedInstance]);

  const formatarDataHora = (data) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    try {
      const dataObj = new Date(data);
      return new Intl.DateTimeFormat("pt-BR", options).format(dataObj);
    } catch (error) {
      return "Data inválida";
    }
  };

  const handleOnChange = (value) => {
    setPhone(value);
    setError(null);
  };

  const handleGenerateQrCode = async () => {
    // Verifica se a instância já está conectada
    if (isConnected) {
      setError("A instância já está conectada.");
      return;
    }

    // Validações do telefone
    if (!phone || phone.length < 10) {
      setError("Número de telefone inválido.");
      return;
    }

    if (
      !selectedInstance?.key ||
      !selectedInstance?.token_instance ||
      !selectedInstance?.base_url
    ) {
      setError("Chave ou token da instância inválidos.");
      return;
    }

    try {
      const requestData = {
        contact: phone,
        key: selectedInstance.key,
        token: selectedInstance.token_instance,
        base_url: selectedInstance.base_url,
      };

      // Chama a API para gerar o QR Code
      const response = await generateQRCode(requestData);

      if (response.success && response.data) {
        setQrCode(response.data.qrcode);
        setShowModal(true); // Abre o modal com o QR Code
      } else {
        setError(response.message || "Erro ao gerar o QR Code.");
      }
    } catch (err) {
      setError("Erro ao processar a requisição. Tente novamente.");
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setQrCode(null);
  };

  // Modal para exibir QR Code e sincronização
  const QrCodeModal = ({ show, base64, onClose, Key, Token, base_url }) => {
    const [qrScanned, setQrScanned] = useState(false);
    const [loading, setLoading] = useState(false); // Estado para controle de carregamento

    useEffect(() => {
      if (base64) {
        const timer = setTimeout(() => {
          console.log("QR Code escaneado automaticamente.");
          setQrScanned(true);

          const closeModalTimer = setTimeout(() => {
            console.log("Fechando modal automaticamente após 5 segundos.");
            onClose?.(); // Fecha o modal
          }, 50000);

          return () => clearTimeout(closeModalTimer);
        }, 50000);

        return () => clearTimeout(timer);
      }
    }, [base64, onClose]);

    const handleSync = () => {
      console.log("Sincronizando com a instância...");
      setLoading(true);

      setTimeout(() => {
        console.log("Sincronização concluída!");
        setQrScanned(true);
        setLoading(false);

        // Atualize o estado do selectedInstance
        setSelectedInstance((prev) => ({
          ...prev,
          status: "on", // Simula que a sincronização ativou a instância
        }));

        // Recarrega a página para garantir atualização completa
        window.location.reload();
      }, 3000);
    };

    /* 
    if (!show) return null; */

    return (
      <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ border: "none" }}>
            <div
              className="modal-header editing-modal-qrcode"
              style={{ border: "none" }}
            >
              <button type="button" className="btn-close" onClick={onClose} />
            </div>
            <div
              id="modal-body-qrcode"
              className="modal-body editing-modal-qrcode"
            >
              {base64 ? (
                <>
                  <img src={base64} alt="QR Code" />
                  <div className="infomations">
                    <div className="info-texts">
                      <div className="info-pass">
                        <i className="fa-solid fa-1"></i>{" "}
                        <span>Aponte sua câmera para o QR Code na tela</span>
                      </div>
                      <div className="info-pass">
                        <i className="fa-solid fa-2"></i>{" "}
                        <span>Aguarde a conexão no telefone</span>
                      </div>
                      <div className="info-pass">
                        <i className="fa-solid fa-3"></i>{" "}
                        <span>Clique em Sincronizar</span>
                      </div>
                    </div>

                    {loading ? (
                      <div className="loading-spinner">
                        <i className="fa fa-spinner fa-spin"></i>{" "}
                        Sincronizando...
                      </div>
                    ) : qrScanned ? (
                      <div className="btn-sinc">
                        <button className="btn-synchronize" disabled>
                          Sincronizado
                        </button>
                      </div>
                    ) : (
                      <>
                        <p style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                          Escaneie o código abaixo com o seu WhatsApp para
                          conectar à instância. Após a conexão, clique em
                          "Sincronizar" para atualizar.
                        </p>

                        <div className="btn-sinc">
                          <button
                            className="btn-synchronize"
                            onClick={handleSync}
                          >
                            Sincronizar
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <p>QR Code não gerado.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!selectedInstance) {
    return <p>Instância selecionada não encontrada.</p>;
  }

  return (
    <section className="dashboard-container-section">
      <Sidebar setIsAuthenticated={() => {}} />
      <div className="container-intance-view">
        <div className="container-cofigure-instance">
          <div className="formValidationContainer">
            <span>Digite seu número de telefone</span>
            <PhoneInput
              className="input-phone"
              country={"br"}
              value={phone}
              onChange={handleOnChange}
              enableAreaCodes={true}
              enableSearch={true}
              placeholder="Digite seu número de telefone"
              inputProps={{
                name: "telefone",
                required: true,
                autoFocus: true,
              }}
              inputStyle={{
                width: "100%",
                height: "50px",
              }}
            />

            {/* Exibição do erro logo abaixo do input */}
            {error && (
              <div
                className="phone-error-message"
                style={{
                  color: "red",
                  fontSize: "14px",
                  marginTop: "0.5rem",
                }}
              >
                {error}
              </div>
            )}

            <div>
              <button
                className="btn-generateQrCode"
                type="button"
                onClick={handleGenerateQrCode}
                disabled={
                  isConnected ||
                  !selectedInstance?.key ||
                  !selectedInstance?.token_instance ||
                  !selectedInstance?.base_url
                }
              >
                <i className="animation"></i> Gerar Qrcode
                <i className="animation"></i>
              </button>
            </div>
          </div>
          <span id="divisor"></span>
          {/* Renderiza o card apenas se houver imagem*/}
          {result && (
            <div className="perfil_instance">
              <h4>
                <strong>Instância:</strong>{" "}
                {selectedInstance.nome_instance || "Sem nome"}
              </h4>
              <div className="imagen-container">
                <div>
                  <p>
                    <strong>Aquecimento:</strong>{" "}
                    {selectedInstance.tipo_aquecimento || "Não especificado"}
                  </p>
                  <p>
                    <strong>Data Início:</strong>{" "}
                    <i className="fa-regular fa-calendar"></i>{" "}
                    {selectedInstance.data_inicio
                      ? formatarDataHora(selectedInstance.data_inicio)
                      : "Não especificada"}
                  </p>
                  <p>
                    <strong>Data Término:</strong>{" "}
                    <i className="fa-regular fa-calendar"></i>{" "}
                    {selectedInstance.data_termino
                      ? formatarDataHora(selectedInstance.data_termino)
                      : "Não especificada"}
                  </p>
                </div>
                {/* Condicionalmente renderiza a imagem se o `block` for "block" */}
                {block === "block" &&
                  typeof result === "string" &&
                  result.trim() !== "" && (
                    <div
                      style={{
                        width: "96px",
                        height: "96px",
                        backgroundColor: "#fff", // Cor de fundo
                        display: "flex",
                        marginLeft: "300px", // Empurra a imagem para a direita
                        alignItems: "center", // Centraliza verticalmente o conteúdo da imagem
                        justifyContent: "center", // Centraliza horizontalmente o conteúdo da imagem
                        overflow: "hidden", // Garante que a imagem não ultrapasse os limites
                      }}
                    >
                      <img
                        src={result}
                        alt="QR Code"
                        onError={(e) => (e.target.src = "/fallback.png")} // Define uma imagem padrão se houver erro
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover", // Mantém a proporção preenchendo o contêiner
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="container-organization">
          <div className="container-header-actions">
            <RadioActionFluxo
              selectedValue={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
            />
          </div>
          <div className="rendered-component">
            {selectedValue === "personalizar" && selectedInstance && (
              <Personalizar
                tokenInstance={selectedInstance.token_instance}
                status={selectedInstance.status}
              />
            )}

            {selectedValue === "templates" && (
              <ContainerTempates showSidebar={false} />
            )}
            {selectedValue === "avancado" && isComponentCAllowed && (
              <Tablefluxo />
            )}
          </div>

          {selectedValue === "avancado" && !isComponentCAllowed && (
            <p>Acesso negado ao Componente Avançado</p>
          )}
        </div>
      </div>

      {/* Modal do QR Code */}
      {showModal && (
        <QrCodeModal
          show={showModal}
          base64={qrCode}
          onClose={handleCloseModal}
          Key={selectedInstance?.key}
          Token={selectedInstance?.token_instance}
          base_url={selectedInstance?.base_url}
        />
      )}
    </section>
  );
};

export default InstanceView;
