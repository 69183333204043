// GraficoLinha.jsx
import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  LineController,
} from "chart.js";

// Registrar os elementos necessários
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  LineController
);

const GraficoLinha = ({ data }) => {
  return (
    <div>
      <Line data={data} />
    </div>
  );
};

export default GraficoLinha;
