import React, { useState, useEffect } from "react";
import { getInstancias } from "../services/instanciaApi"; // Serviço API
import Sidebar from "../components/sidebar";
import "../css/metrica.css";
import ButtonExcel from "./buttonExcel";
import Button from "./buttonDowl";
import PieChart from "./graficoPizza"; // Importando o componente PieChart
import BarChart from "./graficoBarra"; // Importando o componente BarChart
import GraficoLinha from "./graficoLinha"; // Importando o componente GraficoLinha
const CardMetricas = ({ showSidebar = true }) => {
  const [instancias, setInstancias] = useState([]); // Todas as instâncias
  const [instanciasFiltradas, setInstanciasFiltradas] = useState([]); // Instâncias filtradas
  const [instanciaSelecionada, setInstanciaSelecionada] = useState(""); // Instância selecionada
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const carregarInstancias = async () => {
      setIsLoading(true);
      try {
        const response = await getInstancias();
        if (response.success) {
          setInstancias(response.data.instancias);
          setInstanciasFiltradas(response.data.instancias); // Inicializa as instâncias filtradas
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Erro ao carregar instâncias:", error);
      } finally {
        setIsLoading(false);
      }
    };

    carregarInstancias();
  }, []);

  /* useEffect(() => {
    // Filtra as instâncias com base na data selecionada
    if (dataSelecionada) {
      const filtradas = instancias.filter(
        (instancia) => instancia.data === dataSelecionada
      );
      setInstanciasFiltradas(filtradas);
    } else {
      setInstanciasFiltradas(instancias);
    }
  }, [dataSelecionada, instancias]); */

  // Dados para o gráfico de Pizza (PieChart)
  const pieData = {
    labels: ["Instância 1", "Instância 2", "Instância 3"],
    datasets: [
      {
        label: "Distribuição das Instâncias",
        data: [400, 300, 200], // Dados exemplo
        backgroundColor: ["#82ca9d", "#8884d8", "#ff7300"],
        borderColor: ["#82ca9d", "#8884d8", "#ff7300"],
        borderWidth: 1,
      },
    ],
  };

  // Dados para o gráfico de Barras (BarChart)
  const barData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Instâncias por Mês",
        data: [40, 55, 70, 65, 90], // Dados exemplo
        backgroundColor: "#8884d8",
        borderColor: "#8884d8",
        borderWidth: 1,
      },
    ],
  };
  // Dados para o gráfico de linha
  const linhaData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Desempenho das Instâncias ao Longo do Tempo",
        data: [65, 59, 80, 81, 56], // Dados exemplo
        fill: false,
        backgroundColor: "#42A5F5",
        borderColor: "#1E88E5",
      },
    ],
  };

  return (
    <div className="main-container">
      {showSidebar && <Sidebar setIsAuthenticated={() => {}} />}
      <div className="container-historico">
        {/* Título e descrição */}
        <div className="metricas-container">
          <h1 className="title">Métricas</h1>
          <p className="subtitle">
            Aqui você consegue visualizar o desempenhos das instâncias.
          </p>
        </div>

        {/* Contêiner para o card de seleção e gráfico de linha */}
        <div className="selection-and-chart-container">
          <div className="card selection-card">
            <h2>Seleção de Instâncias</h2>
            {/* Container para filtros */}
            <div className="filters-container">
              {/*  <div className="filter-container">
                <label>
                  Data:
                  <input
                    type="date"
                    value={dataSelecionada}
                    onChange={(e) => setDataSelecionada(e.target.value)}
                  />
                </label>
              </div> */}
              <div className="filter-container">
                <label>
                  Instância:
                  <select
                    value={instanciaSelecionada}
                    onChange={(e) => setInstanciaSelecionada(e.target.value)}
                  >
                    <option value="">Selecione uma instância</option>
                    {instanciasFiltradas.map((instancia) => (
                      <option key={instancia.id} value={instancia.id}>
                        {instancia.nome_instance}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>

            {/* Botões */}
            <div className="container-btn-file">
              <ButtonExcel className="button-excel" /> {/* Botão Excel */}
              <Button content="Download" className="button-download" />{" "}
              {/* Botão de Download */}
            </div>

            {/* Mensagem de carregamento ou tabela de instâncias */}
            <div className="container-historico-content">
              {isLoading ? (
                <p>Carregando...</p>
              ) : instanciasFiltradas.length > 0 ? (
                <p>Instâncias disponíveis .</p>
              ) : (
                <p>Nenhuma instância encontrada.</p>
              )}
            </div>
          </div>

          <div className="card chart-card">
            <h3>Desempenho das Instâncias ao Longo do Tempo</h3>
            <GraficoLinha data={linhaData} /> {/* Gráfico de Linha */}
          </div>
        </div>

        {/* Gráficos em Cards */}
        <div className="graficos-container">
          <div className="card">
            <h3>Distribuição das Instâncias</h3>
            <PieChart data={pieData} /> {/* Gráfico de Pizza */}
          </div>
          <div className="card">
            <h3>Desempenho das Instâncias por Mês</h3>
            <BarChart data={barData} /> {/* Gráfico de Barras */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardMetricas;
