import React, { useEffect, useState } from "react";
import { getInstancias, deleteInstancia } from "../services/instanciaApi"; // Função de exclusão importada
import "../css/dashboard.css";
import CardDash from "../components/cardDashboard";
import ProgressCircle from "../components/progressBar";
import Input from "../components/inputBase";
import BootstrapModal from "../components/modal";
import { Link } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import renderPopoverContent from "../components/renderPopover";
import Sidebar from "../components/sidebar";
import useNavigateWithItem from "../hooks/useNavigateWithItem";
import DeleteInstanceModal from "../modal/modalDelete"; // Importando o modal
import Loader from "../components/loader"; // Loader
import iconPage from "../images/icon-page.ico";

function Dashboard() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [instancias, setInstancias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para o termo de pesquisa
  const itemsPerPage = 6;

  const navigateWithItem = useNavigateWithItem();

  // Função para buscar instâncias
  const fetchInstancias = async () => {
    try {
      const result = await getInstancias();
      if (result.success && Array.isArray(result.data.instancias)) {
        setInstancias(result.data.instancias);
      } else {
        setInstancias([]);
      }
    } catch (error) {
      console.error("Erro ao buscar instâncias:", error);
      setInstancias([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Busca as instâncias ao carregar a página
    fetchInstancias();
  }, []);

  // Aplica o filtro com base no termo de pesquisa
  const filteredInstancias = instancias.filter((item) =>
    item.nome_instance.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calcula os itens para a página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInstancias.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredInstancias.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  // Verifica se a data está dentro do mês atual
  const isWithinCurrentMonth = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date();
    return (
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  };

  // Função para abrir o modal de exclusão
  const handleDeleteModal = (instanceName, instanceKey, tokenInstance) => {
    setSelectedInstance({ instanceName, instanceKey, tokenInstance });
    setShowDeleteModal(true);
  };

  // Função para excluir uma instância
  const handleDeleteInstance = async () => {
    if (!selectedInstance) return;

    try {
      const { instanceKey, tokenInstance } = selectedInstance;
      const response = await deleteInstancia(instanceKey, tokenInstance);

      if (response.success) {
        console.log("Instância excluída com sucesso.");
        fetchInstancias(); // Atualiza a lista de instâncias
        setShowDeleteModal(false); // Fecha o modal após a exclusão
      } else {
        console.error("Erro ao excluir instância:", response.message);
      }
    } catch (error) {
      console.error("Erro ao excluir instância:", error);
    }
  };

  return (
    <section className="dashboard-container-section">
      <Sidebar setIsAuthenticated={() => {}} />
      <div className="dashboard-container">
        <div className="square-container">
          <CardDash
            title={"Conectados"}
            content={
              instancias.filter(
                (item) => item.status === "on" && !item.data_inicio
              ).length
            }
            icon={"fa-solid fa-sim-card cn"}
          />
          <CardDash
            title={"Em Aquecimento"}
            content={
              instancias.filter(
                (item) => item.status === "on" && item.data_inicio
              ).length
            }
            icon={"fa-solid fa-fire"}
          />
          <CardDash
            title={"Desconectadas"}
            content={instancias.filter((item) => item.status !== "on").length}
            icon={"fa-solid fa-sim-card ds"}
          />
          <CardDash
            title={"Nº Aquecimentos Mensal"}
            content={
              instancias.filter(
                (item) =>
                  isWithinCurrentMonth(item.data_inicio) && item.status === "on"
              ).length
            }
            icon={"fa-solid fa-star"}
          />
        </div>

        <div className="continer-table">
          <div className="comands-painel">
            <div className="container-search">
              <img
                src={iconPage}
                alt="Ícone personalizado"
                style={{ width: "25px", height: "20px" }}
              />
              {/* Input conectado ao searchTerm */}
              <Input searchTerm={searchTerm} onSearchChange={setSearchTerm} />
              <span>|</span>
              <span>Instâncias: {filteredInstancias.length}</span>
            </div>
            <BootstrapModal fetchInstancias={fetchInstancias} />
          </div>
          <table className="table text-center align-middle">
            <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Aquecimento</th>
                <th scope="col">Status</th>
                <th scope="col">Data Inicio</th>
                <th scope="col">Data Término</th>
                <th scope="col">Progresso</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? ( // Condicional para mostrar a mensagem de "Carregando..."
                <tr>
                  <td colSpan="7">Carregando instâncias, aguarde...</td>
                </tr>
              ) : currentItems.length > 0 ? ( // Se não estiver carregando e tiver itens, exibe os itens
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{item.nome_instance.split("-")[0]}</th>
                    <td>{item.tipo_aquecimento || "group"}</td>
                    <td>
                      {item.data_inicio ? (
                        item.status === "on" ? (
                          <span className="aquecimento">Aquecimento</span>
                        ) : (
                          <span className="disconected">Desconectado</span>
                        )
                      ) : item.status === "on" ? (
                        <span className="conected">Conectado</span>
                      ) : (
                        <span className="disconected">Desconectado</span>
                      )}
                    </td>
                    <td>{formatDate(item.data_inicio)}</td>
                    <td>{formatDate(item.data_termino)}</td>
                    <td>
                      <ProgressCircle
                        dataInicio={item.data_inicio}
                        dataTermino={item.data_termino}
                        status={item.status}
                      />
                    </td>
                    <td>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={renderPopoverContent(
                          item.status,
                          item,
                          navigateWithItem,
                          () =>
                            handleDeleteModal(
                              item.nome_instance,
                              item.key,
                              item.token_instance
                            )
                        )}
                      >
                        <Link to="#">
                          <i className="fa-solid fa-arrow-right"></i>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
              ) : (
                // Se não estiver carregando e não tiver itens, exibe a mensagem "Nenhuma instância encontrada"
                <tr>
                  <td colSpan="7">Nenhuma instância encontrada</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination-container text-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`pagination-btn ${
                  currentPage === index + 1 ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      {showDeleteModal && selectedInstance && (
        <DeleteInstanceModal
          instanceName={selectedInstance.instanceName}
          instanceKey={selectedInstance.instanceKey}
          tokenInstance={selectedInstance.tokenInstance}
          onDeleteSuccess={handleDeleteInstance} // Atualiza o Dashboard após exclusão
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </section>
  );
}

export default Dashboard;
