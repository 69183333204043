import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importa o hook para navegação
import { Toast, ToastContainer } from "react-bootstrap";
import ButtonAquece from "../components/buttonAquece";
import { createFluxo } from "../services/fluxo_aqueApi";
import "../css/personaliza.css";

const Personalizar = ({ tokenInstance, status }) => {
  const [erro, setErro] = useState("");
  const [valorValido, setValorValido] = useState(false);
  const [days, setDays] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [inputHabilitado, setInputHabilitado] = useState(false);

  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    if (status === "on") {
      setInputHabilitado(true);
    } else {
      setInputHabilitado(false);
    }
  }, [status]);

  const validarInput = (event) => {
    const valor = event.target.value;
    setDays(valor);
    if (
      !/^\d{1,3}$/.test(valor) ||
      parseInt(valor) < 1 ||
      parseInt(valor) > 10
    ) {
      setErro("Por favor, insira um número entre 1 e 10.");
      setValorValido(false);
    } else {
      setErro("");
      setValorValido(true);
    }
  };

  const enviarFluxo = async () => {
    if (!tokenInstance) {
      console.error("Token da instância não encontrado.");
      setToastMessage("Erro: Nenhuma instância selecionada.");
      setShowToast(true);
      return;
    }

    try {
      setLoading(true);
      const payload = {
        token_instance: tokenInstance,
        days: parseInt(days),
        media: null,
        type: "group",
        destinos: null,
      };

      const result = await createFluxo(payload.token_instance, payload.days);

      if (result.success) {
        setToastMessage("Aquecimento criado com sucesso!");
        setShowToast(true);
        console.log("Resposta da API:", result.data);

        // Redireciona o usuário para o dashboard
        setTimeout(() => navigate("/dashboard"), 2000);
      } else {
        setToastMessage(`Erro ao criar o aquecimento: ${result.message}`);
        setShowToast(true);
        console.error("Erro da API:", result.message);
      }
    } catch (error) {
      setToastMessage("Erro ao enviar o fluxo. Tente novamente.");
      setShowToast(true);
      console.error("Erro inesperado durante o envio:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-pensonalization-simples">
      <h3>Configuração Rápida</h3>

      <div className="container-fluxosSimples">
        <div className="form-later-fluxos">
          <label id="indicatorDays" className="indicatorDays">
            Insira o número de dias do fluxo
          </label>
          <input
            className="form-control"
            type="text"
            name="fluxoDays"
            id="fluxoDays"
            value={days}
            onInput={validarInput}
            pattern="\d{1,3}"
            required
            disabled={!inputHabilitado}
          />

          {erro && (
            <p style={{ color: "red", fontSize: "12px", padding: "10px 0px" }}>
              {erro}
            </p>
          )}

          {valorValido && (
            <div className="button-aquecime">
              <ButtonAquece
                onClick={enviarFluxo}
                loading={loading}
                content={"AQUECER"}
              />
            </div>
          )}
        </div>
      </div>

      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          autohide
          bg={toastMessage.startsWith("Erro") ? "danger" : "success"}
        >
          <Toast.Body className="text-white">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default Personalizar;
