// ModalComponent.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalComponent = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Monte seu Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Aqui voce irá configurar os seus templates </p>
        <p><strong>aqui é consideravel as configurações:</strong></p>
        <ul>
          <li>nome do template</li>
          <li>dias de aquecimento</li>
          <li>tipo de aquecimento</li>
          <li>tipo de mensagens</li>

        </ul>
        <p>em breve....</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" onClick={() => alert('Em breve você poderá criar seus templates aqui ')}>
          Salvar mudanças
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
