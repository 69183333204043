import { Popover } from "react-bootstrap";

// Estilos para o componente
const links = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
  textDecoration: "none",
  color: "#000000",
  fontFamily: "Inria Sans",
  cursor: "pointer",
};

const renderPopoverContent = (
  status,
  item,
  navigateWithItem,
  setShowDeleteModal
) => {
  const handleAction = (actionType) => {
    if (actionType === "deletar") {
      setShowDeleteModal(true); // Chama a função para abrir o modal de exclusão
    } else {
      navigateWithItem(actionType, item);
    }
  };

  if (status === "on") {
    return (
      <Popover id="popover-basic">
        <Popover.Body className="d-flex flex-column">
          <div
            className="p-2"
            onClick={() => handleAction("conectar")}
            style={links}
          >
            <i className="fa-solid fa-network-wired"></i>

            <span> Acessar</span>
          </div>
          {/*      <div
            className="p-2"
            onClick={() => handleAction("Desconectar")}
            style={links}
          >
            <i className="fa-solid fa-phone-slash"></i>

            <span> Desconectar</span>
          </div> */}
          <div
            className="p-2"
            style={links}
            onClick={() => handleAction("deletar")}
          >
            <i className="fa-solid fa-trash"></i>
            <span> Deletar</span>
          </div>
        </Popover.Body>
      </Popover>
    );
  } else {
    return (
      <Popover id="popover-basic">
        <Popover.Body className="d-flex flex-column">
          <div
            className="p-2"
            onClick={() => handleAction("conectar")}
            style={links}
          >
            <i className="fa-solid fa-qrcode"></i>
            <span> Conectar</span>
          </div>

          <div
            className="p-2"
            onClick={() => handleAction("deletar")}
            style={links}
          >
            <i className="fa-solid fa-trash"></i>
            <span> Deletar</span>
          </div>
        </Popover.Body>
      </Popover>
    );
  }
};

export default renderPopoverContent;
